import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { colors, md } from '../../common'

export default () => (
    <Layout>
      <SEO
        title={'Tudor Watches'}
        description="When Tudor was first introduced back in the United States, Underwood’s in Jacksonville, FL was one of the first retailers to carry them. These fine Swiss timepieces fit any personality." />
      <div css={`padding:2rem;text-align:center;${md}{padding:1rem;}`}>
        <img css={`max-width:300px;width:100%;`} src={'https://s3.amazonaws.com/underwoods/_designers/tudor/logo.png'} alt={`logo for tudor`} />
        <p css={`color:${colors.greyDarker};padding:2rem;line-height:24px;${md}{padding:1rem;}`}>Tudor watches have a long history for quality and impeccable design at a great price.  At Underwood's, we are proud to have been one of the first official Tudor retailers when the brand was reintroduced in the United States in 2013. If you're looking for a luxury watch with a vintage or throwback style, Tudor is a great way to go. Other designs suit discerning tastes. Check out Underwood's current inventory at our stores in Ponte Vedra and San Marco.</p>
        <div>
          <iframe title="tudor mandated iframe" id="tudor-digital-corner" src="https://www.tudorwatch.com/corners/tudor_800x500?lang=en&amp;dealerDomain=www.underwoodjewelers.com&amp;dealerAPIKey=c8181a49983723edacb987b3682aca31" frameBorder="0" css={`width:800px;height:500px;${md}{display:none;}`} style={{border:'none',overflow:'hidden'}}></iframe>
        </div>
      </div>
    </Layout>
)
